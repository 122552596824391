.Modal {
  width: 100%;
  height: calc(var(--vh, 1%) * 100);
  position: fixed;
  top: var(--scr, 0px);
  left: 0;
  right: 0;
  z-index: 99;
  overflow: hidden;

  &_background {
    width: 100%;
    height: calc(var(--vh, 1%) * 100);
    position: absolute;
    top: 0;
    left: 0;
    background: #051932;
    opacity: 0.6;
  }

  &_button-cancel {
    position: absolute;
    top: 9.39px;
    left:50%;
    transform:translate(-50%); 
    width: 22px;
    height: 22px;
    cursor: pointer;

    &_bg1, &_bg2 {
      position: absolute;
      width: 22px;
      height: 22px;
    }

    &_bg1 {
      background: url(../../icons/x1.svg)
    }

    &_bg2 {
      background: url(../../icons/x2.svg)
    }
  }

  &_board {
    position: absolute;
    left:50%;
    transform:translate(-50%); 
    width: 320px;
    height: calc(var(--vh, 1%) * 100 - 40px);
    top: 40px;
    background: #FFFFFF;
    box-shadow: 0px 16px 32px rgba(17, 16, 52, 0.04);
    border-radius: 10px 10px 0px 0px;

    &_header {
      margin: 24px auto 12px 12px;
      font-size: 24px;
      line-height: 24px;
      width: 110px;
      height: 24px;
    }

    &_btns {
      position: absolute;
      bottom: 0;
      box-sizing: border-box;
      width: 320px;
      height: 50px;
      padding: 5px 12px;
      display: flex;
      justify-content: space-between;
      background: #FFFFFF;
      opacity: 0.8;
      border-radius: 10px 10px 0px 0px;

      &_cancel, &_submit {
        width: 145px;
        height: 40px;
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        border: none;
        border-radius: 8px;
        cursor: pointer;

      }

      &_cancel {
        background: #E6E8EB;
        color: #051932;
      }

      &_submit {
        background: #00C0D1;
        color: #FFFFFF;
      }
    }
  }
}