.Card {

  &_item, &_item-empty {
    width: 276px;
    height: 276px;
    position: relative;
    background: #DADDE1;
    border-radius: 20px;
    cursor: pointer;

    &_text {
      position: absolute;
      height: 120px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      border-radius: 0px 0px 20px 20px;
      background: linear-gradient(180deg, rgba(5, 25, 50, 0) 0%, rgba(5, 25, 50, 0.6) 100%);

      & span {
        position: relative;
        top: 48px;
        left: 20px;
        font-size: 24px;
        line-height: 24px;
        color: #FFFFFF;
      }
    }
  }

  &_item {

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center top;
      border-radius: 20px;
    }
    
  }

  &_item-empty {
    display: flex;

    & img {
      margin: auto;
    }
  }      
}

@media all and (max-width: 1279px) {
    .Card {

      &_item, &_item-empty {
        width: 191px;
        height: 191px;
        
        &_text {
          height: 72px;

          & span {
            top: 16px;
            left: 12px;
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
    }
}

@media all and (max-width: 1024px) {
  .Card {

    &_item, &_item-empty {
      width: 200px;
      height: 200px;
    }
  }      
}

@media all and (max-width: 719px) {
  .Card {

    &_item, &_item-empty {
      width: 144px;
      height: 144px;
      border-radius: 12px;

      &_text {
        height: 72px;
        border-radius: 0px 0px 12px 12px;
      }

      & img {
        border-radius: 12px;
      }
    }
  }
}